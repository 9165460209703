<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header border-0 bg-default">
      <!--begin::Navs-->
      <div class="d-flex flex-column w-100">
        <div class="d-flex overflow-auto h-55px mb-7">
          <ul
            class="
              nav nav-stretch nav-line-tabs nav-line-tabs-2x
              border-transparent
              fs-5
              fw-bolder
              flex-nowrap
            "
          >
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                to="/tps-map"
                class="nav-link text-active-white text-warning me-6"
                active-class="active"
              >
                Peta Koordinator
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                class="nav-link text-active-white text-warning me-6"
                to="/tps-map/table"
                active-class="active"
              >
                Daftar Saksi
              </router-link>
            </li>
            <!--end::Nav item-->
          </ul>
        </div>
      </div>
      <!--end::Navs-->
      <!--begin::Card title-->
      <div class="card-title m-0 d-flex justify-content-between w-100 mb-4">
        <h3 class="fw-bolder m-0 text-white">Cari Wilayah</h3>
        <div
          class="
            float-end
            d-flex
            align-items-right
            justify-content-between
            w-75
          "
        >
          <select
            name=""
            id=""
            class="form-select me-2"
            @input="getKecamatan"
            v-model="form.kecamatan"
          >
            <option value="" selected>Pilih Kecamatan</option>
            <option
              v-for="(item, i) in kecamatan"
              :key="i"
              :value="item.territory.id"
            >
              {{ item.territory.name }}
            </option>
          </select>
          <select
            name=""
            id=""
            class="form-select me-2"
            @input="getKelurahan"
            v-model="form.kelurahan"
          >
            <option value="" selected>Pilih Kelurahan</option>
            <template v-if="kelurahan">
              <option
                v-for="(item, i) in kelurahan"
                :key="i"
                :value="item.territory.id"
              >
                {{ item.territory.name }}
              </option>
            </template>
          </select>
          <select
            @input="getTps"
            v-model="form.tps"
            name=""
            id=""
            class="form-select w-50"
          >
            <option value="" selected>Pilih TPS</option>
            <template v-if="tps">
              <option v-for="(item, i) in tps" :key="i" :value="item.tps.id">
                {{ item.tps.no_tps }}
              </option>
            </template>
          </select>
        </div>
      </div>
      <!--end::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Content-->
    <div id="kt_account_profile_details" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body p-5">
        <div class="table-responsive">
          <table class="table table-row-bordered table-striped">
            <thead>
              <tr class="fs-6 fw-bolder border-bottom">
                <th>Kecamatan</th>
                <th>Kelurahan</th>
                <th>No.TPS</th>
                <th>Nama Saksi</th>
                <th>NIK</th>
                <th>Alamat</th>
                <th>Whatsapp</th>
                <th>Foto KTP</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in data" :key="i">
                <td>{{ item.kecamatan }}</td>
                <td>{{ item.kelurahan }}</td>
                <td>{{ item.noTps }}</td>
                <td>{{ item.saksi }}</td>
                <td>{{ item.nik }}</td>
                <td>{{ item.alamat }}</td>
                <td>{{ item.whatsapp }}</td>
                <td>
                  <div
                    class="
                      d-flex
                      justify-content-center
                      align-items-center
                      flex-column
                    "
                  >
                    <img
                      class="img-fluid"
                      style="max-height: 50px"
                      :src="item.ktp"
                      alt="foto kegiatan"
                    />
                    <a
                      class="btn btn-sm btn-default"
                      target="_blank"
                      :href="item.ktp"
                      >Lihat foto</a
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
</template>

<script>
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "Heatmaps",
  setup() {
    setCurrentPageTitle("Daftar Saksi");

    const store = useStore();
    // const router = useRouter();

    store.dispatch("AllTerritory", { type: 4 });
    store.dispatch("AllTPS");

    const markers = computed(() => store.state.TPSModule.all);
    const data = ref([]);

    const kecamatan = computed(() => store.state.TerritoryModule.kecamatan);
    const kelurahan = computed(() => store.state.TerritoryModule.kelurahan);
    const tps = computed(() => store.state.ResultModule.tps);

    const form = ref({
      kecamatan: "",
      kelurahan: "",
      tps: "",
    });

    watch(markers, () => {
      data.value = [];
      console.log(markers.value.length);
      if (markers.value && markers.value.length) {
        markers.value.map((item) => {
          data.value.push({
            kecamatan: item.parent_territory.name,
            kelurahan: item.territory.name,
            noTps: item.tps.no_tps,
            saksi: item.witness.name,
            nik: item.witness.nik,
            alamat: item.witness.domisili,
            whatsapp: item.witness.phone_number,
            ktp: item.witness.foto_ktp,
          });
        });
      }
    });

    const getKecamatan = (el) => {
      form.value.kelurahan = "";
      form.value.tpsId = "";
      store.commit("SET_TPS_RESULT", { tpses: [] });
      if (el.target.value) {
        store.dispatch("AllTerritory", { type: 5, parent_id: el.target.value });
        updateDataWilayah(el.target.value);
      } else {
        updateTanpaWilayah();
        store.commit("SET_ALL_TERRITORY", { type: 5, territories: [] });
      }
    };

    const getKelurahan = (el) => {
      if (el.target.value) {
        store.dispatch("getTPSResult", { territory: el.target.value });
        updateDataWilayah(el.target.value);
      } else {
        form.value.tpsId = "";
        store.commit("SET_TPS_RESULT", { tpses: [] });
        updateDataWilayah(form.value.kecamatan);
      }
    };

    const getTps = (el) => {
      updateDataTPS(el.target.value);
    };

    const updateDataWilayah = (wilayah) => {
      store.dispatch("AllTPS", { territory: wilayah });
    };

    const updateTanpaWilayah = () => {
      store.dispatch("AllTPS");
    };

    const updateDataTPS = (tps) => {
      const wilayah = form.value.kelurahan
        ? form.value.kelurahan
        : form.value.kecamatan;
      store.dispatch("AllTPS", { territory: wilayah, tps: tps });
    };

    return {
      data,
      kecamatan,
      kelurahan,
      tps,
      form,
      getKecamatan,
      getKelurahan,
      getTps,
    };
  },
});
</script>
